import React from "react"

import * as S from "./style"

const Office = () => (
  <S.Office id="cargos">
    <S.Title>Cargos / Experiência</S.Title>
    <S.TextWrapper>
      <S.Text>
        Sócio administrador do escritório Torrens e Fabre Advocacia
      </S.Text>
      <S.Text>
        Assessor Jurídico da Federação Catarinense de Texas Hold’em
      </S.Text>
      <S.Text>
        Membro da Comissão de Direito de Família e Sucessões da OAB/SC
      </S.Text>
    </S.TextWrapper>
  </S.Office>
)

export default Office
