import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const Header = styled.header`
  width: 100%;
  border-bottom: 2px solid var(--gold);
`

export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan("medium")`
    justify-content: center;
  `}
`

export const LogoWrapper = styled.div`
  width: 7rem;

  ${media.lessThan("large")`
    width: 5rem;
  `}
`

export const Logo = styled(Img)`
  width: 100%;
`

export const LinksWrapper = styled.div`


  ${media.lessThan("medium")`
    display: none;
  `}
`

export const Link = styled.a`
  font-size: 1rem;
  font-weight: 600;
  color: var(--black);
  text-decoration: none;
  margin-right: 0.5rem;
  transition: all .2s ease-in-out;

  &:hover{
    color: var(--gold);
  }
`