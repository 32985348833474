import styled from "styled-components"
import media from "styled-media-query"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

export const BannerImage = styled(BackgroundImage)`
  width: 100%;
  background-position: center 0%;
`

export const BannerMask = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  position: relative;
`

export const BannerWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 128px 32px;

  ${media.lessThan("medium")`
    flex-direction: column;
    padding: 64px 32px;
  `}

  ${media.lessThan("small")`
    padding: 32px;
  `}
`

export const TextsWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 32px;

  ${media.lessThan("medium")`
    width: 100%;
    margin-right: 0;
  `}
`

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  color: var(--gold);

  ${media.lessThan("medium")`
    font-size: 2rem;
  `}
`

export const Text = styled.p`
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--white);
  line-height: 1.75rem;

  ${media.lessThan("medium")`
    font-size: 1rem;
  `}
`

export const Button = styled.a`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding: 1.25rem 4rem;
  border: none;
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.8);
  background: var(--gold);
  font-size: 1rem;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.1);
  }
`

export const IconWrapper = styled.div`
  width: 1rem;
  height: 1.1rem;
  margin-right: 0.25rem;
`

export const BannerPhoto = styled(Img)`
  width: 100%;
  border-radius: 100%;
  max-width: 320px;
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.8);

  ${media.lessThan("medium")`
    max-width: 280px;
    order: -1;
    margin-bottom: 16px;
  `}
`
