import styled from "styled-components"
import media from "styled-media-query"

export const QuoteWrapper = styled.div`
  width: 424px;
  min-height: 324px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--darkBackground);

  ${media.lessThan("948px")`
    width: 380px;
    min-height: 260px;
  `}

  ${media.lessThan("860px")`
    width: 424px;
    min-height: 320px;
  `}

  ${media.lessThan("524px")`
    width: 256px;
    min-height: 220px;
    padding: .5rem 1rem;
  `}
`

export const Quote = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: var(--gold);
  text-align: center;
  line-height: 1.5rem;

  &::before {
    content: "“";
    margin-right: .25rem;
    font-size: 1.5rem;
  }

  &::after {
    content: "”";
    margin-left: .25rem;
    font-size: 1.5rem;
  }

  ${media.lessThan("948px")`
    font-size: 1rem;
    line-height: 1.25rem;
    
    &::before {
      font-size: 1.25rem;
    }
  
    &::after {
      font-size: 1.25rem;
    }
  `}

  ${media.lessThan("860px")`
    font-size: 1.25rem;
    line-height: 1.5rem;
    
    &::before {
      font-size: 1.5rem;
    }

    &::after {
      font-size: 1.5rem;
    }
  `}

  ${media.lessThan("524px")`
    margin-bottom: .5rem;
    font-size: .8rem;
    line-height: 1rem;
    
    &::before {
      font-size: 1rem;
    }

    &::after {
      font-size: 1rem;
    }
  `}
`

export const Name = styled.h3`
  font-size: 1rem;
  color: var(--gold);
  text-align: center;

  ${media.lessThan("948px")`
    font-size: .8rem;
  `}

  ${media.lessThan("860px")`
    font-size: 1rem;
  `}

  ${media.lessThan("524px")`
    font-size: .6rem;
  `}
`