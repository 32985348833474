import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./style"

const Navbar = () => {
  const { avatarImage } = useStaticQuery(
    graphql`
      query {
        avatarImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <S.Header>
      <S.HeaderWrapper>
        <S.LogoWrapper>
          <S.Logo fluid={avatarImage.childImageSharp.fluid} />
        </S.LogoWrapper>
        <S.LinksWrapper>
          <S.Link href="#cargos">Cargos</S.Link>
          <S.Link href="#especialidades">Especialidades</S.Link>
          <S.Link href="#depoimentos">Depoimentos</S.Link>
          <S.Link href="#contatos">Contatos</S.Link>
        </S.LinksWrapper>
      </S.HeaderWrapper>
    </S.Header>
  )
}

export default Navbar
