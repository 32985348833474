import React from "react"

import { Bank } from "@styled-icons/boxicons-solid/Bank"
import { FileEarmarkTextFill as Contract } from "@styled-icons/bootstrap/FileEarmarkTextFill"
import { PersonDashFill } from "@styled-icons/bootstrap/PersonDashFill"
import { Handshake } from "@styled-icons/fa-solid/Handshake"
import {AttachMoney as Money} from "@styled-icons/material-rounded/AttachMoney"
import { Documents } from "@styled-icons/ionicons-solid/Documents"
import { HouseUser } from "@styled-icons/fa-solid/HouseUser"
import {ChildFriendly as Child} from "@styled-icons/material/ChildFriendly"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"

import Area from "../Area"

import * as S from "./style"

const PracticeAreas = () => (
  <S.PracticeAreasWrapper id="especialidades"> 
    <S.Title>Especialidades</S.Title>

    <S.PracticeAreasContainer>
      <Area title="Negociação e Revisional Bancária">
        <Bank />
      </Area>
      <Area title="Contratos">
        <Contract />
      </Area>
      <Area title="Dano Moral">
        <PersonDashFill />
      </Area>
      <Area title="Conciliação e Acordo Extrajudicial">
        <Handshake />
      </Area>
      <Area title="Nome no SPC/Serasa">
        <Money />
      </Area>
      <Area title="Divórcio e Inventário">
        <Documents />
      </Area>
      <Area title="Usucapião">
        <HouseUser />
      </Area>
      <Area title="Guarda e Alimentos">
        <Child />
      </Area>
    </S.PracticeAreasContainer>
    <S.Button 
      href="https://api.whatsapp.com/send?phone=5548999211000&text=Ol%c3%a1!+Sim%2c+gostaria+de+falar+direto+com+o+Advogado+e+receber+uma+consultoria+gratuita+agora%2c+sem+compromisso."
    >
      <S.IconWrapper>
        <Whatsapp />
      </S.IconWrapper>
      Entre em Contato!
    </S.Button>
  </S.PracticeAreasWrapper>
)

export default PracticeAreas