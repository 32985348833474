import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"

import * as S from "./style"

const Banner = () => {
  const { bannerImage, bannerPhoto } = useStaticQuery(
    graphql`
      query {
        bannerImage: file(relativePath: { eq: "backgroundBanner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerPhoto: file(relativePath: { eq: "bannerPhoto.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <S.BannerImage
      Tag="section"
      fluid={bannerImage.childImageSharp.fluid}
      fadeIn
    >
      <S.BannerMask>
        <S.BannerWrapper>
          <S.TextsWrapper>
            <S.Title>Dr. Flávio Fabre</S.Title>
            <S.Text>
              Apresenta mais de 17 anos de atividades atuando como advogado e
              prestando assessoria jurídica para pessoas físicas e empresas.
            </S.Text>
            <S.Text>
              Desempenha função profissional focada na necessidade do cliente e
              solução de problemas com ampla experiência em negociações e
              demandas judiciais.
            </S.Text>
            <S.Button href="https://api.whatsapp.com/send?phone=5548999211000&text=Ol%c3%a1!+Sim%2c+gostaria+de+falar+direto+com+o+Advogado+e+receber+uma+consultoria+gratuita+agora%2c+sem+compromisso.">
              <S.IconWrapper>
                <Whatsapp />
              </S.IconWrapper>
              Entre em Contato!
            </S.Button>
          </S.TextsWrapper>
          <S.BannerPhoto fluid={bannerPhoto.childImageSharp.fluid} />
        </S.BannerWrapper>
      </S.BannerMask>
    </S.BannerImage>
  )
}

export default Banner
