import styled from "styled-components"
import media from "styled-media-query"

export const AreaWrapper = styled.div`
  width: 180px;
  border-radius: 0.5rem;
  margin: 0.75rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--darkBackground);
  transition: all .5s ease-in-out;

  &:hover{
    transform: scale(1.1);
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0,0.8);
  }

  ${media.lessThan("small")`
    width: 120px;
    margin: 0.5rem;
  `}
`

export const IconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
  color: var(--gold);

  ${media.lessThan("small")`
    width: 2rem;
    height: 2rem;
  `}
`

export const Title = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: var(--gold);
  text-align: center;
  line-height: 1.25rem;
  margin: 0;

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`