import styled from "styled-components"

export const PracticeAreasWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 4rem auto;
  padding: 1rem;
`

export const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 800;
  color: var(--black);
  text-transform: uppercase;
  text-align: center;
`

export const PracticeAreasContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
`

export const Button = styled.a`
  display: flex;
  align-items: center;  
  margin-top: 2rem;
  padding: 1.25rem 4rem;
  border: none;
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0,0.8);
  background: var(--gold);
  font-size: 1rem;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .5s;

  &:hover{
    transform: scale(1.1)
  }
`

export const IconWrapper = styled.div`
  width: 1rem;
  height: 1.1rem;
  margin-right: .25rem;
`