import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Quote from "../Quote"

import { quotes } from "./content"
import * as S from "./style"

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <S.TestimonialsWrapper id="depoimentos">
      <S.Title>Depoimentos</S.Title>
      <Slider {...settings}>
        {quotes.map((quote, index) => <div key={index}>
           <Quote index={index}
            name={quote.client} 
            text={quote.quote} 
          />
        </div>)}
      </Slider>
    </S.TestimonialsWrapper>
  )
}

  export default Testimonials