import React from "react"
import { EmailOutline as EmailIcon } from "@styled-icons/evaicons-outline/EmailOutline"
import { Phone as PhoneIcon } from "@styled-icons/boxicons-solid/Phone"
import { Map as MapIcon } from "@styled-icons/boxicons-solid/Map"
import { Law as LawIcon } from "@styled-icons/octicons/Law"
import { InstagramAlt as Instagram } from "@styled-icons/boxicons-logos/InstagramAlt"
import { FacebookSquare as Facebook } from "@styled-icons/boxicons-logos/FacebookSquare"

import Map from "../Map"

import * as S from "./style"

const Contact = () => (
  <S.ContactWrapper id="contatos">
    <S.ContactContainer>
      <S.MapWrapper>
        <S.Title>Localização</S.Title>
        <Map />
      </S.MapWrapper>

      <S.InfosContainer>
        <S.Title>Fale Conosco</S.Title>

        <S.InfoWrapper>
          <a
            href="https://www.instagram.com/flavio.fabre.adv/"
            title="Meu Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <S.SocialWrapper>
              <Instagram />
            </S.SocialWrapper>
          </a>
          <a
            href="https://www.facebook.com/flavio.fabre"
            title="Meu Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <S.SocialWrapper>
              <Facebook />
            </S.SocialWrapper>
          </a>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <MapIcon />
          </S.IconWrapper>
          <S.Info>
            Rua Araújo Figueiredo, 119, sala 104, Centro, Florianópolis/SC, CEP
            88.010-520
          </S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <EmailIcon />
          </S.IconWrapper>
          <S.Info>flaviofabre@gmail.com</S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <PhoneIcon />
          </S.IconWrapper>
          <S.Info>(48) 99921-1000</S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <LawIcon />
          </S.IconWrapper>
          <S.Info>OAB/SC n° 21.598</S.Info>
        </S.InfoWrapper>
      </S.InfosContainer>
    </S.ContactContainer>
  </S.ContactWrapper>
)

export default Contact
