export const quotes = [
  {
    client: "Noeli Pires - Proprietária da Pousada Dora",
    quote:
      "Em 2018, precisei  de um advogado e conversei com o Dr. Fabre sobre o assunto, ele foi super atencioso e me explicou tudo como eu deveria proceder. Dali por diante sempre que preciso de qualquer assessoria jurídica quem faz tudo pra mim é o Dr. Flávio Fabre. Profissional seríssimo, super competente e muito amável. Só tenho que agradecer aos seus serviços prestados.",
  },
  {
    client: "Fábio Eiji - Professional Poker Player",
    quote:
      "Dr. Fabre vem sendo uma referência jurídica em seu trabalho na Federacao Catarinense de Texas Hold'em, contribuindo com o amparo legal necessário para o desenvolvimento da atividade em nossa região. Recomendo seu trabalho e avalizo sua competência.",
  },
  {
    client: "Elias Goulart - Empresário",
    quote:
      "Num dos momentos mais difíceis pude contar com o Doutor Flávio para resolver minhas solicitações, sempre muito prestativo, atencioso e esclarecedor com todas as dúvidas que tinha. Já conto com ele há mais de cinco anos, sempre obtendo sucesso em nossos processos. Estou extremamente satisfeito com sua maneira de atuar e feliz com seus serviços prestados!",
  },
  {
    client: "Alexandre Goldani - Corretor de Imoveis",
    quote:
      "Conheci o Dr Fabre em 2015 por indicação de um amigo, posso dizer que sou muito grato por ter essa indicação. Muito prestativo, atencioso e sempre muito claro em todas as orientações, o Dr Fabre me ajuda muito em todas as minhas negociações e contratos. Depois de muitos anos procurando, hoje eu posso dizer que tenho um advogado de confiança. ",
  }
]