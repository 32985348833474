import styled from "styled-components"

export const Office = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 4rem auto;
  padding: 1rem;
`

export const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 800;
  color: var(--black);
  text-transform: uppercase;
  text-align: center;
`

export const TextWrapper = styled.div`

`

export const Text = styled.p`
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--black);
  text-align: center;
`